(function($) {
    jQuery(".accButton").click(function () {
        var button = jQuery(this);
        var buttonID = button.attr("id");
        
        console.log(buttonID);

        var collection = accommodationCollection;
        var acc = collection[buttonID];

        console.log(acc);


        var displayTitle = jQuery(".accommodationDisplay .accommodationTitle");
        var displayContent = jQuery(".accommodationDisplay .accommodationContent");


        displayTitle.html(acc.post_title);
        displayContent.html(acc.post_content);

    });
})(jQuery);


